<template>
  <div v-if="isEdit" class="row">
    <div class="col filter_toolbar mb-2">
      <div class="search_wrapper flex-grow-1">
        <input
          id="search_query"
          v-model="searchQuery"
          class="form-control"
          placeholder="Search students..."
        >
      </div>
      <div class="d-flex flex-column align-items-center">
        <label class="col-form-label col-form-label-sm">
          Hide Students with OLH Done
        </label>
        <div class="form-check">
          <input
            v-model="hideInitialClassPassed"
            class="form-check-input d-block"
            type="checkbox"
          >
        </div>
      </div>
    </div>
  </div>
  <div class="students_table_wrapper">
    <table class="app_table table table-striped table-hover table-sm">
      <thead class="table-light">
        <tr>
          <th v-if="isEdit" scope="col" class="th_checkbox is_active" />
          <th scope="col">
            Name
          </th>
          <th scope="col">
            Surname
          </th>
          <th scope="col">
            E-mail
          </th>
          <th scope="col">
            Class Language
          </th>
          <th scope="col">
            Level
          </th>
          <th scope="col">
            Location
          </th>
          <th scope="col">
            Pause Until
          </th>
          <th class="th_checkbox" scope="col">
            Flexi Classes
          </th>
          <th class="th_checkbox" scope="col">
            OLH Done
          </th>
          <th class="th_checkbox" scope="col">
            Is Active
          </th>
          <th v-if="!isEdit" class="th_checkbox remove_item" scope="col" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="student in students"
          :key="student.id"
          @click="toggleSelectedStudent(student.id)"
        >
          <td v-if="isEdit" class="td_checkbox is_active">
            <template v-if="selectSingleStudent">
              <input
                v-model="selectedStudentId"
                :value="student.id"
                class="form-check-input d-block"
                type="radio"
              >
            </template>
            <template v-else>
              <input
                v-model="selectedStudents"
                :value="student.id"
                class="form-check-input d-block"
                type="checkbox"
              >
            </template>
          </td>
          <td class="td_name">
            {{ student.first_name }}
          </td>
          <td class="td_surname">
            {{ student.last_name }}
          </td>
          <td>{{ student.email }}</td>
          <td>{{ getMapping("class_language", student.class_language, "-") }}</td>
          <td>{{ getMapping("level", student.level, "-") }}</td>
          <td>{{ getMapping("class_location", student.class_location, "-") }}</td>
          <td class="td_checkbox">
            {{ student?.pause_until ? formatDate(student?.pause_until) : "" }}
          </td>
          <td class="td_checkbox">
            <BooleanCheckCircle :is-true="student.flexible_classes_count > 0" />
          </td>
          <td class="td_checkbox">
            <BooleanCheckCircle :is-true="student.initial_course_passed" />
          </td>
          <td class="td_checkbox">
            <BooleanCheckCircle :is-true="student.is_active" />
          </td>
          <td v-if="!isEdit" class="td_checkbox remove_item hover-pointer" @click="removeClassStudent(student.id)">
            <i class="bi bi-trash3-fill text-danger" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";
import { useStudentStore } from "@/stores/student.store";
import useDateMixin from "@/mixins/date.mixin";

export default defineComponent({
  name: "SelectStudentsTable",
  components: { BooleanCheckCircle },
  mixins: [mappingsMixin],
  props: {
    // Show initially selected students in the beginning of all rows.
    initialSelectedStudents: {
      type: Array as () => number[],
      default: () => [],
    },
    selectSingleStudent: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["selected-students-changed", "update:hideInitialClassPassed"],
  setup() {
    const studentStore = useStudentStore();
    const { students, searchQuery, hideInitialClassPassed, selectedStudentIds } = toRefs(studentStore);
    const { formatDate } = useDateMixin();

    return { studentStore, students, formatDate, searchQuery, hideInitialClassPassed, selectedStudentIds }
  },
  data() {
    return {
      // This array is used if we are selecting multiple students.
      selectedStudents: [] as number[],
      selectedStudentId: undefined as number | undefined,
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.studentStore.getStudents(this.initialSelectedStudents);
      },
    },
    hideInitialClassPassed() {
      this.studentStore.getStudents(this.initialSelectedStudents);
    },
    selectedStudents() {
      this.$emit("selected-students-changed", this.selectedStudents);
    },
    selectedStudentId() {
      if (this.selectedStudentId) {
        this.selectedStudentIds = [this.selectedStudentId];
      } else {
        this.selectedStudentIds = [];
      }
    },
  },
  created() {
    this.selectedStudents = [...this.initialSelectedStudents];
    if (this.selectSingleStudent) {
      this.selectedStudentId = this.initialSelectedStudents[0];
    }
    this.studentStore.getStudents(this.initialSelectedStudents);
  },
  methods: {
    toggleSelectedStudent(studentId?: number) {
      if (!studentId) return;

      // Find the index of the student ID in the array
      const index = this.selectedStudents.indexOf(studentId);

      if (this.selectSingleStudent) {
        if (this.selectedStudentId === studentId) {
          // Unselect the radio, selected student.
          this.selectedStudents = [];
          this.selectedStudentId = undefined;
        } else {
          this.selectedStudents = [studentId];
          this.selectedStudentId = studentId;
        }
      } else {
        // If the student ID is not found, add it to the array
        if (index === -1) {
          this.selectedStudents.push(studentId);
        } else {
          // If the student ID is found, remove it from the array
          this.selectedStudents.splice(index, 1);
        }
      }
    },
    removeClassStudent(studentId?: number) {
      if (studentId == null) return;
      this.selectedStudents.splice(this.selectedStudents.indexOf(studentId), 1);
    },
  },
});
</script>

<style scoped lang="scss">
.students_table_wrapper {
  overflow: auto;
  height: 100%;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
.filter_toolbar {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .search_wrapper {
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    input {
      margin-bottom: 0;
    }
  }
}
tr:hover {
  cursor: pointer;
}
.td_surname,
.th_surname {
  width: 12rem;
  min-width: 12rem;
}
.td_name,
.th_name {
  width: 6rem;
  min-width: 6rem;
}
.th_checkbox,
.td_checkbox {
  width: 4rem;
  min-width: 3rem;

  &.is_active {
    width: 50px;

    .form-check-input {
      width: 1.2em;
      height: 1.2em;
    }
  }
  &.remove_item {
    width: 50px;
    font-size: 1.1rem;
  }
}
</style>
