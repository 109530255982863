<template>
  <table
    class="app_table table table-sm"
    :class="{ 'table-striped': tableStriped }"
  >
    <thead class="table-light">
      <tr>
        <th scope="col" class="th_checkbox">
          #
        </th>
        <th
          v-tooltip="'Attended'"
          class="th_checkbox"
          scope="col"
        >
          ATT
        </th>
        <th
          v-tooltip="'No Show'"
          class="th_checkbox"
          scope="col"
        >
          NS
        </th>
        <th class="th_recouped" scope="col">
          Recouped
        </th>
        <th scope="col" class="th_name">
          Name
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(attendance, index) in event.attendances"
        :key="attendance.id"
      >
        <td class="td_checkbox">
          {{ index + 1 }}
        </td>
        <td class="td_checkbox">
          <BooleanCheckCircle :is-true="attendance.attended" />
        </td>
        <td class="td_checkbox">
          <BooleanCheckCircle :is-true="attendance.no_show" />
        </td>
        <td class="td_recouped_event">
          {{ formatDate(attendance.recouped_event?.start, true, false) || "-" }}
        </td>
        <td class="td_name">
          {{ attendance.student_name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import { useModalStore } from "@/stores/modal.store";
import useDateMixin from "@/mixins/date.mixin";
import { useStudentStore } from "@/stores/student.store";
import setsMixin from "@/mixins/sets.mixin";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";
import CalendarClassEvent from "@/types/models/calendar_class_event";

export default defineComponent({
  name: "EventAttendanceTableLite",
  components: { BooleanCheckCircle },
  mixins: [mappingsMixin, setsMixin],
  props: {
    event: {
      type: CalendarClassEvent,
      default: () => new CalendarClassEvent(),
    },
    tableStriped: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const modalStore = useModalStore();
    const studentStore = useStudentStore();
    const { attendanceStudents: loadingAttendanceStudents } = toRefs(studentStore.loading);
    const loadingEventAttendances = ref(false);
    const searchQuery = ref("");

    const { formatDate, formatTime } = useDateMixin();
    return {
      modalStore,
      studentStore,
      formatDate,
      formatTime,
      loadingAttendanceStudents,
      loadingEventAttendances,
      searchQuery,
    };
  },
});
</script>

<style scoped lang="scss">
.th_recouped {
  text-align: right;
}
.td_surname,
.th_surname {
  width: 12rem;
  min-width: 12rem;
}
.th_recouped,
.td_recouped_event {
  width: 9rem;
  min-width: 14rem;
  text-align: center;
}
.th_checkbox,
.td_checkbox {
  width: 3rem !important;
}
</style>
