<template>
  <div class="container main-container g-0 school_class_events">
    <FullScreenLoadingSpinner v-if="loading" />

    <div v-else class="row">
      <SchoolClassEventsTable
        :school-class="schoolClass"
        :events="events"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import FullScreenLoadingSpinner from "@/components/ui/spinner/FullScreenLoadingSpinner.vue";
import SchoolClass from "@/types/models/school_class";
import SchoolClassEventsTable from "@/components/modals/calendar/school_class/tabs/SchoolClassEventsTable.vue";
import CalendarClassEvent from "@/types/models/calendar_class_event";

export default defineComponent({
  name: "SchoolClassEvents",
  components: {
    FullScreenLoadingSpinner,
    SchoolClassEventsTable,
  },
  mixins: [mappingsMixin],
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClass>,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const loading = ref(false);
    const events = ref<CalendarClassEvent[]>([]);

    const { formatDate, formatTime } = useDateMixin();
    return { formatDate, formatTime, loading, events };
  },
  created() {
    if (this.schoolClass.id) {
      this.loading = true;
      api.calendarApi.getSchoolClassEvents(this.schoolClass)
        .then((events) => {
          this.events = events.data;
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loading = false));
    }
  },
});
</script>

<style scoped lang="scss">
.school_class_events {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 50vh;
  overflow: hidden;

  .row {
    row-gap: 0.6em;
  }
  label {
    font-weight: bold;
  }
}
</style>
