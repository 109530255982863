<template>
  <div v-if="student" class="attendances">
    <div class="table_wrapper">
      <table class="app_table table table-striped table-hover table-sm">
        <thead class="table-light">
          <tr>
            <th class="th_index" scope="col">
              #
            </th>
            <th class="th_date" scope="col">
              Date
            </th>
            <th class="th_number_of_classes" scope="col">
              Time
            </th>
            <th class="th_class_title" scope="col">
              Class
            </th>
            <th class="th_number_of_classes" scope="col">
              Teacher
            </th>
            <th class="th_attended th_checkbox" scope="col">
              ATT
            </th>
            <th class="th_no_show th_checkbox" scope="col">
              NS
            </th>
            <th class="th_recouped_date" scope="col">
              Recouped Date
            </th>
            <th class="th_recouped_class" scope="col">
              Recouped Class
            </th>
            <th scope="col">
              Class Purchase
            </th>
          </tr>
        </thead>
        <tbody v-if="loadingAttendances">
          <tr>
            <td colspan="10">
              <LoadingSpinner class="my-2" text="Loading Class Purchases..." />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <template v-for="(attendance, index) in attendances" :key="index">
            <tr>
              <th scope="row">
                {{ attendances.length - index }}
              </th>
              <td class="td_date">
                {{ formatDate(attendance.recouped_event?.start || attendance.event?.start, true, false) }}
              </td>
              <td class="td_time">
                {{ formatTime(attendance.recouped_event?.start || attendance.event.start) }} -
                {{ formatTime(attendance.recouped_event?.end || attendance.event.end) }}
              </td>
              <td class="th_class_title">
                {{ attendance.recouped_event?.title || attendance.event.title }}
              </td>
              <td class="td_class">
                {{ getMapping("teacher",attendance.recouped_event?.teacher || attendance.event.teacher)?.title }}
              </td>
              <td class="td_checkbox td_attended">
                <BooleanCheckCircle :is-true="isAttendanceAttended(attendance)" />
              </td>
              <td class="td_checkbox td_no_show">
                <BooleanCheckCircle :is-true="isAttendanceNoShow(attendance)" />
              </td>
              <td class="td_recouped_date">
                <template v-if="attendance.recouped_event">
                  <strong>{{ recoupedEventTitle(attendance) }}</strong>
                </template>
                <!--                <span-->
                <!--                  v-if="attendance.recouped_event"-->
                <!--                  v-tooltip="attendance.recouped_event?.title"-->
                <!--                >-->
                <!--                  <i class="bi bi-info-circle-fill text-secondary" />-->
                <!--                </span>-->
              </td>
              <td class="td_recouped_class">
                <template v-if="attendance.recouped_event">
                  {{ attendance.recouped_event?.title }}
                </template>
              </td>
              <td class="td_class_purchase td_checkbox">
                <BooleanCheckCircle :is-true="attendance.class_purchase != null" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import Student from "@/types/models/student";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";

export default defineComponent({
  name: "StudentAttendances",
  components: { BooleanCheckCircle, LoadingSpinner },
  mixins: [mappingsMixin],
  props: {
    modelValue: {
      type: Object as PropType<Student>,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const loadingAttendances = ref(false);
    const attendances = ref<IStudentEventAttendanceExtended[]>([]);

    const { formatDate, formatTime } = useDateMixin();
    return { formatDate, formatTime, loadingAttendances, attendances };
  },
  computed: {
    student(): Student {
      return this.modelValue;
    },
  },
  created() {
    if (this.student.id) {
      this.loadingAttendances = true;
      api.userApi
        .getStudentAttendances(this.student.id)
        .then((attendances) => {
          this.attendances = attendances;
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loadingAttendances = false));
    }
  },
  methods: {
    recoupedEventTitle(attendance: IStudentEventAttendanceExtended) {
      if (!attendance.recouped_event) return "";
      // This is the title of the event the student attended to recuperate the No Show event.
      const e = attendance.event;
      return `${this.formatDate(e.start)} ${this.formatTime(e.start)} - ${this.formatTime(e.end)}`;
    },
    isAttendanceAttended(attendance: IStudentEventAttendanceExtended) {
      // If the event was recouped, it means that the original attendance was a no-show.
      if (attendance.recouped_event) return false;
      return attendance.attended;
    },
    isAttendanceNoShow(attendance: IStudentEventAttendanceExtended) {
      // If the event was recouped, it means that the original attendance was a no-show.
      if (attendance.recouped_event) return true;
      return attendance.no_show;
    },
  },
});
</script>

<style scoped lang="scss">
.attendances {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .table_wrapper {
    overflow: auto;
    margin-top: 1rem;
    @include sm {
      margin-top: 0;
    }
  }
  table {
    border-collapse: collapse;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .td_class_title,
  .th_class_title {
    min-width: 14rem;
  }
  .th_index {
    width: 1rem;
  }
  .td_time,
  .td_date {
    min-width: 8rem;
    width: 8rem;
    vertical-align: middle;
  }
  .td_class {
    min-width: 12rem;
    width: 12rem;
  }
  .td_checkbox.td_no_show,
  .td_checkbox.td_attended,
  .th_attended,
  .th_no_show {
    width: 3rem;
    min-width: 3rem;
  }
  .td_recouped_date {
    width: 12.5rem;
    min-width: 12.5rem;
  }
  .td_class_purchase {
    width: 7.5rem;
  }
  td {
    vertical-align: middle;
  }
}
</style>
