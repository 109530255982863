import axios from "axios";
import * as api from "./modules";
export * as api from "./modules";

// AXIOS
export const axiosClient = axios.create({
  baseURL: (import.meta.env.VITE_API_URL || "http://localhost:8000") + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Request Interceptor (Attach Access Token)
axiosClient.interceptors.request.use((config) => {
  const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");

  if (tokens?.access) {
    config.headers.Authorization = `Bearer ${tokens.access}`;
  }
  return config;
});

// Response Interceptor (Handle Expired Tokens)
axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite loops
      console.warn("try refresh access token")
      try {
        // Attempt to refresh the token
        const newAccessToken = await api.authApi.refreshToken();
        console.warn("newAccessToken access token", newAccessToken)

        // Update the stored token
        const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
        tokens.access = newAccessToken;
        localStorage.setItem("tokens", JSON.stringify(tokens));
        console.warn("refresh access token DONE")

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosClient(originalRequest);
      } catch (refreshError) {
        console.error("Refresh token failed. Logging out.");
        api.authApi.destroyToken();
        window.location.href = "/login"; // Redirect to login page
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);
