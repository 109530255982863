import { defineStore } from "pinia";
import { api } from "@/api";
import { useMappingsStore } from "@/stores/mappings.store";
import FullCalendar from "@fullcalendar/vue3";
import CalendarClassEvent from "@/types/models/calendar_class_event";
import { useToastStore } from "@/stores/toast.store";
import SchoolClass from "@/types/models/school_class";
import CalendarEvent from "@/types/models/calendar_event";
import { EventType } from "@/types/enums/event_type";

export const useCalendarStore = defineStore({
  id: "calendar",
  state: () => ({
    weeklyCalendar: null as typeof FullCalendar | null,
    refreshSchoolClasses: 0,
    loading: {
      schoolClasses: false,
      classEvents: false,
    },
  }),
  getters: {
    mappingsStore() {
      return useMappingsStore();
    },
    toastStore() {
      return useToastStore();
    },
  },
  actions: {
    getEventFromFullCalendarEvent(dateEventInfo: any) {
      const eventType = dateEventInfo?.event?.extendedProps?.event_type ?? EventType.CLASS;

      if ([EventType.CLASS, EventType.OUR_LANGUAGE_HOUR].includes(eventType)) {
        return this.classEventFromCalendarEvent(dateEventInfo);
      }
      return this.backgroundEventFromCalendarEvent(dateEventInfo, eventType);
    },
    classEventFromCalendarEvent(dateEventInfo: any): CalendarClassEvent {
      /**
       * Create a ClassCalendarEvent instance from the clicked
       * or selected FullCalendar event info.
       **/
      // An existing event was passed, and we want to edit it.
      if (dateEventInfo.event) {
        return new CalendarClassEvent({
          id: dateEventInfo.event.id,
          teacher: dateEventInfo.event.extendedProps.teacher,
          school_class: dateEventInfo.event.extendedProps.school_class,
          title: dateEventInfo.event.title,
          start: dateEventInfo.event.startStr,
          end: dateEventInfo.event.endStr,
          notes: dateEventInfo.event.extendedProps.notes,
          attendances: dateEventInfo.event.extendedProps.attendances,
          event_type: dateEventInfo.event.extendedProps.event_type,
        });
      }

      // Empty date slot was passed, and we want to create a new class event.
      return new CalendarClassEvent(this.parseFullCalendarEventData(dateEventInfo, EventType.CLASS));
    },
    backgroundEventFromCalendarEvent(dateEventInfo: any, eventType?: EventType): CalendarEvent {
      /**
       * Create a background CalendarEvent instance from the clicked
       * or selected FullCalendar event info.
       **/
      // An existing event was passed, and we want to edit it.
      if (dateEventInfo.event) {
        return new CalendarEvent({
          id: dateEventInfo.event.id,
          teacher: dateEventInfo.event.extendedProps.teacher,
          title: dateEventInfo.event.title,
          start: dateEventInfo.event.startStr,
          end: dateEventInfo.event.endStr,
          notes: dateEventInfo.event.extendedProps.notes,
          event_type: dateEventInfo.event.extendedProps.event_type,
        });
      }

      // Empty date slot was passed, and we want to create a new class event.
      return new CalendarEvent(this.parseFullCalendarEventData(dateEventInfo, eventType));
    },
    parseFullCalendarEventData(dateEventInfo: any, eventType?: EventType) {
      // Setup default start and end dates.
      let startDate: Date = new Date();
      let endDate: Date = new Date(startDate);
      // Increment end date by one hour.
      endDate.setUTCHours(startDate.getUTCHours() + 1, startDate.getUTCMinutes());

      if ("start" in dateEventInfo) {
        startDate = dateEventInfo?.start;
        endDate = dateEventInfo?.end;
      } else if ("date" in dateEventInfo) {
        startDate = dateEventInfo?.date;
        endDate = new Date(startDate);
        if (eventType === EventType.CLASS) {
          endDate.setUTCHours(startDate.getUTCHours() + 1, startDate.getUTCMinutes());
        } else {
          endDate.setUTCMinutes(startDate.getUTCMinutes() + 30);
        }
      }

      return {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        teacher: dateEventInfo?.resource.id, // teacher ID
        event_type: eventType,
      };
    },
    async getAllSchoolClasses() {
      this.loading.schoolClasses = true;
      return api.calendarApi
        .getAllSchoolClasses()
        .then(({ data }) => {
          data = data.map((schoolClass: any) => ({
            ...schoolClass,
            queryString: schoolClass.title + " " + schoolClass.student_names.join(" "),
          }))
          this.mappingsStore.addMapping(data, "school_class", "title");
          // this.mappingsStore.addChoices(data, "school_class", "title");
        })
        .catch((error: Error) => console.error(error))
        .finally(() => (this.loading.schoolClasses = false));
    },
    async getClassEvents(info: any, successCallback: any, failureCallback: any) {
      this.loading.classEvents = true;

      return api.calendarApi
        .getEvents(info.startStr, info.endStr)
        .then((data) => {
          // The successCallback function must be called when the custom event function has generated
          // its events. It is the event function’s responsibility to make sure successCallback is
          // being called with an array of parsable Event Objects.
          successCallback(data);
        })
        .catch((error) => failureCallback(error))
        .finally(() => (this.loading.classEvents = false));
    },
    async saveEvent(event: CalendarClassEvent | CalendarEvent) {
      if (event.event_type === EventType.CLASS) {
        return this.saveClassEvent(event as CalendarClassEvent);
      }
      return this.saveBackgroundEvent(event as CalendarEvent);
    },
    async saveClassEvent(classEvent: CalendarClassEvent) {
      const inputErrors = classEvent.validate();

      if (Object.keys(inputErrors).length > 0) return Promise.reject(inputErrors);

      let response;
      try {
        if (classEvent.id == null) {
          // Create new class event.
          response = await api.calendarApi.createClassEvent(classEvent);
          // Show success message.
          this.toastStore.addToast("A new class event was created successfully.");
        } else {
          // Update the existing class event.
          response = await api.calendarApi.updateClassEvent(classEvent);
          // Show success message.
          this.toastStore.addToast("Class event was updated successfully.");
        }
      } catch (error: any) {
        Object.keys(error).forEach(function (key) {
          error[key] = error[key].join("\n");
        });
        return Promise.reject(error);
      }

      return response?.data;
    },
    async saveBackgroundEvent(event: CalendarEvent) {
      let response;
      try {
        if (event.id == null) {
          // Create new class event.
          response = await api.calendarApi.createBackgroundEvent(event);
          // Show success message.
          this.toastStore.addToast("A new background event was created successfully.");
        } else {
          // Update the existing class event.
          response = await api.calendarApi.updateBackgroundEvent(event);
          // Show success message.
          this.toastStore.addToast("Background event was updated successfully.");
        }
      } catch (error: any) {
        Object.keys(error).forEach(function (key) {
          error[key] = error[key].join("\n");
        });

        this.toastStore.errorToast(error.non_field_errors ?? "Something went wrong creating a background event.", 10);
        return Promise.reject(error);
      }

      return response?.data;
    },
    async saveSchoolClass(schoolClass: SchoolClass) {
      const inputErrors = schoolClass.validate();

      if (Object.keys(inputErrors).length > 0) return Promise.reject(inputErrors);

      let response;
      try {
        if (schoolClass.id == null) {
          // Create new class event.
          response = await api.calendarApi.createSchoolClass(schoolClass);
          // Show success message.
          this.toastStore.addToast("A new class was created successfully.");
        } else {
          // Update the existing class.
          response = await api.calendarApi.updateSchoolClass(schoolClass);
          // Show success message.
          this.toastStore.addToast("Class was updated successfully.");
        }

        // Refresh all classes, no need to await.
        this.getAllSchoolClasses();
        this.refreshSchoolClasses += 1;
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function (key) {
          error[key] = error[key].join("\n");
        });
        return Promise.reject(error);
      }

      return response?.data;
    },
    async deleteSchoolClass(schoolClass: SchoolClass) {
      const inputErrors = schoolClass.validate();

      if (Object.keys(inputErrors).length > 0) return Promise.reject(inputErrors);

      let response;
      try {
        response = await api.calendarApi.deleteSchoolClass(schoolClass);
        this.toastStore.addToast("Class was deleted successfully.");

        // Refresh all classes, no need to await.
        this.mappingsStore.removeMappingById("school_class", schoolClass?.id);
        this.refreshSchoolClasses += 1;
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function (key) {
          error[key] = error[key].join("\n");
        });
        return Promise.reject(error);
      }

      return response?.data;
    },
  },
});
