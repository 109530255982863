<template>
  <div class="events_table table_wrapper">
    <table
      id="events_table"
      class="app_table table_expandable table table-striped table-hover"
    >
      <thead class="table-light">
        <tr>
          <th scope="col">
            #
          </th>
          <th class="th_date" scope="col">
            Date
          </th>
          <th scope="col">
            Title
          </th>
          <th class="th_number_of_classes" scope="col">
            Students
          </th>
          <th class="th_number_of_classes" scope="col">
            ATT
          </th>
          <th class="th_number_of_classes" scope="col">
            NS
          </th>
          <th class="th_notes" scope="col">
            Notes
          </th>
          <th class="th_action th_checkbox" scope="col" />
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td colspan="9">
            <LoadingSpinner class="my-2" text="Loading Attendances..." />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="(event, index) in events ?? []" :key="index">
          <tr class="hover-pointer" @click="toggle(event)">
            <th scope="row">
              {{ events?.length ?? 0 - index }}
            </th>
            <td class="td_date">
              {{ formatDate(event.start, true, false) }}
              {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
            </td>
            <td
              class="td"
            >
              {{ event.title }}
            </td>
            <td class="td_number_of_classes">
              <span
                class="attendance_badge badge rounded-pill bg-secondary"
              >
                <i class="bi bi-people-fill" /> {{ event.attendances?.length }}
              </span>
            </td>
            <td class="td_number_of_classes">
              {{ getAttendedCount(event) }}
            </td>
            <td class="td_number_of_classes">
              {{ getNoShowCount(event) }}
            </td>
            <td
              v-tooltip="event.notes"
              class="td_notes text_ellipsis"
            >
              {{ event.notes }}
            </td>
            <td class="td_action">
              <div class="td_action__content">
                <span class="ms-4">
                  <i v-if="(event as any).is_open" class="bi bi-chevron-up" />
                  <i v-else class="bi bi-chevron-down" />
                </span>
              </div>
            </td>
          </tr>
          <!-- Class Attendances -->
          <tr class="events_table__tr_attendances">
            <td
              colspan="9"
              class="accordion-collapse collapse"
              :class="{ show: (event as any).is_open }"
            >
              <div
                v-if="(event as any).is_open"
                class="events_table__attendances"
              >
                <div class="d-flex flex-column">
                  <strong>Event Notes</strong>
                  <div class="events_table__notes">
                    {{ event.notes }}
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <strong>Event Attendances</strong>
                  <EventAttendanceTableLite
                    :event="event"
                  />
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import SchoolClass from "@/types/models/school_class";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import CalendarClassEvent from "@/types/models/calendar_class_event";
import EventAttendanceTableLite from "@/components/modals/calendar/event/EventAttendanceTableLite.vue";

export default defineComponent({
  name: "SchoolClassEvents",
  components: { EventAttendanceTableLite, LoadingSpinner },
  mixins: [mappingsMixin],
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClass>,
      default: () => {
        return {};
      },
    },
    events: {
      type: Array as () => CalendarClassEvent[],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { formatDate, formatTime } = useDateMixin();
    return { formatDate, formatTime };
  },
  methods: {
    toggle(event: any) {
      event.is_open = !event.is_open;
    },
    getAttendedCount(event: CalendarClassEvent) {
      return event?.attendances.filter((attendance) => attendance.attended)?.length || 0;
    },
    getNoShowCount(event: CalendarClassEvent) {
      return event?.attendances.filter((attendance) => attendance.no_show)?.length || 0;
    },
  },
});
</script>

<style scoped lang="scss">
.events_table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  margin-top: 1rem;

  @include md {
    height: 600px;
    max-height: 600px;
  }
  #events_table thead {
    z-index: 20;
  }
  &__tr_attendances {
    td {
      background: #efefef;
    }
  }
  &__attendances {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__notes {
    border: 1px dotted lightgray;
    border-radius: 0.25rem;
    padding: 0.5rem;
    background: #f8f9fa;
    min-width: 16rem;
    height: auto;
    min-height: 3rem;
    flex-grow: 1;
  }
}
.table {
  .th_action,
  .td_action {
    width: 4rem;
    font-size: 1rem;
    padding-right: 1rem;

    &__content {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }
  .td_number_of_classes,
  .th_number_of_classes {
    width: 4rem;
    min-width: 4rem;
  }
  .td_date {
    min-width: 15rem;
    width: 15rem;
  }
  .th_notes,
  .td_notes {
    width: 12rem;
    max-width: 12rem;
  }
}
</style>
