import { axiosClient } from "@/api";

class AuthApi {
  private failedAttempts: number;
  constructor() {
    this.failedAttempts = 0;
  }
  login(email: string, password: string): Promise<any> {
    return axiosClient.post("/auth/login/", { email, password });
  }
  async refreshToken(): Promise<any> {
    const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");

    if (!tokens?.refresh) {
      console.error("No refresh token available. Logging out.");
      this.destroyToken();
      window.location.href = "/login";
      return Promise.reject(new Error("No refresh token available"));
    }

    return axiosClient
      .post(
        "/auth/login-refresh/",
        { refresh: tokens.refresh },
      )
      .then((response) => {
        this.failedAttempts = 0;
        this.saveToken(response.data);
        return response.data.access;
      })
      .catch((error) => {
        console.error("FAIL refresh token", error);
        this.failedAttempts += 1;

        if (this.failedAttempts >= 3 || error.response?.status === 401) {
          console.error("Exceeded maximum refresh attempts. Logging out.");
          this.destroyToken();
          window.location.href = "/login";
        }

        return Promise.reject(error);
      });
  }
  saveToken(tokens: Record<string, string>) {
    localStorage.setItem("tokens", JSON.stringify(tokens));
  }
  destroyToken() {
    localStorage.removeItem("tokens");
  }
}

export const authApi = new AuthApi();
