import { useMappingsStore } from "@/stores/mappings.store";
import { computed, defineComponent } from "vue";
import ISelectInputChoice from "@/types/input";

export default defineComponent({
  methods: {
    getMapping(key: string, id: any, defaultVal: string = "") {
      if (!id) {
        return defaultVal;
      }
      const mapping = computed(() => useMappingsStore().mappings[key]);
      const mappedValue = mapping.value?.[id];

      if (mappedValue == null) {
        console.error(`Mapping value for id '${id}' does not exist in '${key}' mappings.`);
        return id;
      }
      return mappedValue;
    },
    getChoices(key: string): Array<ISelectInputChoice> {
      const choices = computed(() => useMappingsStore().choices);
      const mappedChoices = choices.value[key];

      if (mappedChoices == null) {
        console.error(`Mapping value for key '${key}' does not exist in '${key}' choices.`);
        return [];
      }

      return mappedChoices;
    },
    getObjects<T>(key: string): Array<T> {
      const objects = computed(() => useMappingsStore().objects);
      const mappedObjects = objects.value[key];

      if (mappedObjects == null) {
        console.error(`Mapping value for key '${key}' does not exist in '${key}' objects.`);
        return [];
      }

      return mappedObjects as Array<T>;
    },
    getDefaultChoice<T>(key: string) {
      const options = this.getChoices(key);
      return options.length > 0 ? options[0].id as T: undefined;
    },
  },
});
