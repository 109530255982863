import { defineStore } from "pinia";
import { api } from "@/api";
import IMappings, { IMappingObject, IMappingsSelectInputChoices } from "@/types/mappings";
import ISelectInputChoice from "@/types/input";
import { ITeacherCalendarResource } from "@/types/teacher";
import ISchoolClass from "@/types/school_class";
import IStudent from "@/types/student";


export const useMappingsStore = defineStore({
  id: "mappings",
  state: () => ({
    mappings: {
      student: {},
      teacher: {},
      school_class: {},
    } as IMappings,
    choices: {
      student: [],
      studentToLink: [],
      teacher: [],
      school_class: [],
    } as IMappingsSelectInputChoices,
    objects: {
      student: [] as IStudent[],
      teacher: [] as ITeacherCalendarResource[],
      school_class: [] as ISchoolClass[],
    } as IMappings,
    loading: false,
  }),
  actions: {
    addMapping<T extends IDType>(values: T[], mappingKey: string, nameKey: string) {
      if (!this.mappings[mappingKey]) {
        // Ensure it exists
        this.mappings[mappingKey] = {};
      }
      console.log("[MAPPING_STORE] addMapping", mappingKey, nameKey);

      values.forEach((obj) => {
        const idValue = obj.id;
        if (idValue !== undefined && idValue !== null) {
          this.mappings[mappingKey][idValue] = obj; // Add to existing mapping
        }
      });

      this.addChoices(values, mappingKey, nameKey)
    },
    addChoices<T extends IDType>(values: T[], mappingKey: string, nameKey: string) {
      this.objects[mappingKey] = values;

      this.choices[mappingKey] = values.map(
        (obj: any) => ({
          ...obj,
          id: obj.id,
          name: obj[nameKey],
        }),
      );
    },
    clearKey(mappingKey: string) {
      console.log("[MAPPING_STORE] clearKey", mappingKey);
      this.mappings[mappingKey] = {};
      this.choices[mappingKey] = [];
      this.objects[mappingKey] = [];
    },
    async getMappings(): Promise<void> {
      this.loading = true;

      return api.mappingsApi
        .getMappings()
        .then(({ data }: {data: IMappings}) => {
          // Reduce mappings object to a list of (key, value) pairs for all mappings
          // that can be used in the select input options.
          for (const [key, values] of Object.entries(data)) {
            this.mappings[key] = values;
            this.choices[key] = reduceToArray(values);
          }
        })
        .catch((error: Error) => console.error(error))
        .finally(() => (this.loading = false));
    },
    removeMappingById(mappingKey: string, id?: string | number) {
      if (!id) return;

      // Remove from mappings
      if (this.mappings[mappingKey]) {
        delete this.mappings[mappingKey][id];
      }

      // Remove from choices
      if (this.choices[mappingKey]) {
        this.choices[mappingKey] = this.choices[mappingKey].filter(choice => choice.id !== id);
      }

      // Remove from objects
      if (this.objects[mappingKey]) {
        this.objects[mappingKey] = this.objects[mappingKey].filter((obj: any) => obj.id !== id);
      }
    },
  },
});

const reduceToArray = (values: any): ISelectInputChoice[] => {
  try {
    return Object.entries(values).map(([key, value]) => {
      return {
        id: <string>key,
        name: <string>value,
      };
    });
  } catch (e) {
    console.error(e);
    return [];
  }
};
