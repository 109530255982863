<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down overflow-hidden h-100 m-auto">
    <div class="modal-content overflow-hidden h-100">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="selectSingleStudent">
            Select Our Language Hour Student
          </template>
          <template v-else>
            Select Students
          </template>
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body overflow-hidden h-100">
        <div v-if="schoolClassStudents" class="container-fluid main-container g-0 overflow-hidden h-100">
          <div class="row overflow-hidden h-100">
            <div class="col overflow-hidden h-100 d-flex flex-column">
              <SelectStudentsTable
                :initial-selected-students="initialSchoolClassStudents"
                :is-edit="true"
                :select-single-student="selectSingleStudent"
                @selected-students-changed="selectedStudentsChanged"
              />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="schoolClassStudents"
        :pagination="pagination"
        @page-changed="pageChanged"
      />
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="close"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="confirmSelection">
          Confirm Selection
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRaw, toRefs } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import SelectStudentsTable from "@/components/modals/student/SelectStudentsTable.vue";
import Pagination from "@/components/ui/pagination/Pagination.vue";
import { useStudentStore } from "@/stores/student.store";


export default defineComponent({
  name: "SelectStudentsSubModal",
  components: { SelectStudentsTable, Pagination },
  mixins: [mappingsMixin],
  props: {
    selectedStudentIds: {
      type: Array as () => number[],
      default: () => [],
    },
    selectSingleStudent: {
      type: Boolean,
      default: false,
    },
    selectedStudentsCallback: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup(props) {
    // Create a copy to prevent modifying the parent.
    const schoolClassStudents = ref<number[]>([...props.selectedStudentIds]);
    const initialSchoolClassStudents = ref<number[]>([...props.selectedStudentIds]);
    console.log("INIT", toRaw(initialSchoolClassStudents));
    const studentStore = useStudentStore();
    const { pagination } = toRefs(studentStore);
    studentStore.resetState();

    if (props.selectSingleStudent) {
      studentStore.hideInitialClassPassed = true;
      studentStore.selectedStudentIds = [props.selectedStudentIds[0]];
    } else {
      studentStore.selectedStudentIds = props.selectedStudentIds;
    }

    return {
      initialSchoolClassStudents,
      schoolClassStudents,
      studentStore,
      pagination,
    };
  },
  methods: {
    confirmSelection() {
      this.selectedStudentsCallback(this.schoolClassStudents);
      this.close();
    },
    close() {
      this.$emit("close");
    },
    selectedStudentsChanged(newStudents: number[]) {
      this.schoolClassStudents = newStudents;
      this.studentStore.selectedStudentIds = newStudents;
    },
    pageChanged(page: number) {
      this.pagination.page = page;
      // Pass initially selected students
      this.studentStore.getStudents(this.initialSchoolClassStudents);
    },
  },
});
</script>
<style scoped lang="scss">
.modal-content {
  min-height: 10rem;
  max-height: 95vh;
  margin: auto;
}
@include sm {
  .modal-content {
    height: auto;
  }
  .modal-body {
    overflow-x: hidden;
  }

  .modal-dialog.modal-xl {
    --bs-modal-width: 1300px;
  }
}
</style>
